<template>
    <div>
      <CCard>
        <CCardBody>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <button class="btn btn-sm btn-success ml-2" @click="addProducts()">
                  <CIcon name="cilMedicalCross" />
                  Tambah
                </button>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <label class="m-1 ml-3" for="">Search : </label>
                <input
                  type="text"
                  v-model="params.keyword"
                  style="max-width: 200px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                />
                <button @click="search()" class="btn btn-sm btn-success">
                  Cari
                </button>
              </div>
            </div>
            <div class="col-2">
              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Per Halaman</label
                      >
                    </div>
                    <select
                      class="custom-select"
                      style="max-width: 100px"
                      id="inputGroupSelect01"
                      v-model="params.row"
                      @change="getData()"
                    >
                      <!-- <option selected>Pilih...</option> -->
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="isSearching">
            <div class="col">
              <h3>
                <span class="badge bg-primary text-light text-bor my-auto">
                  {{ searchOn }}&nbsp;&nbsp;
                  <span
                    @click="searchOff"
                    class="badge bg-light text-dark text-center"
                    style="cursor: pointer"
                    >X</span
                  >
                </span>
              </h3>
            </div>
          </div>
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            sorter
          >
            <template #image="{ item }">
              <td class="py-2">
                <img
                  :src="item.image"
                  width="80px"
                  style="cursor: pointer"
                  @click="showLargeImageModal = true; largeImageSrc = item.image"
                />
              </td>
            </template>
            <template #content="{ item }">
              <div v-html="item.content"></div>
            </template>
            <template #select="{ item }">
              <td class="py-2">
                <input
                  type="checkbox"
                  @change="check(item)"
                  v-model="item.select"
                />
              </td>
            </template>
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  @click="edit(item)"
                  class="mr-2"
                  color="warning"
                  square
                  size="sm"
                >
                  Edit
                </CButton>
                <CButton @click="hapus(item)" color="danger" square size="sm">
                  Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="100"
            @paginate="pagination"
          />
        </CCardBody>
      </CCard>
      <CModal
        size="lg"
        :title="isUpdate ? 'Edit Produk' : 'Tambah Produk'"
        centered
        :color="isUpdate ? 'success' : 'primary'"
        :show.sync="createModal"
      >
        <div class="row">
            <div class="col-12">
            <CInput v-model="form.name" label="Nama" placeholder="ketik disini" />
            </div>
            <div class="col-12">
                <label class="form-label" for="type">Type</label>
                <CSelect v-model="form.type" :options="typeOptions" placeholder="Select Type"></CSelect>
            </div>
            <div class="col-12">
            <label class="form-label" >Content</label>
            <ckeditor
                :editor="editor"
                v-model="form.content"
                :config="editorConfig"
            ></ckeditor>
            </div>
            <div class="col-12">
            <label class="form-label" for="newData.image">Photo</label>
            <input
                type="file"
                class="form-control mb-2"
                id="newData.image"
                @change="selectImage"
            />
            </div>
        </div>
        <template slot="footer">
          <div>
            <button @click="createModal = false" class="btn btn-secondary mr-3">
              Batal
            </button>
            <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
              Tambah Produk
            </button>
            <button @click="update" v-if="isUpdate" class="btn btn-primary">
              Update Produk
            </button>
          </div>
        </template>
      </CModal>
      <CModal
        size="lg"
        centered
        :show.sync="showLargeImageModal"
      >
        <img :src="largeImageSrc" alt="Large Image" style="max-width: 100%;" />
        <template slot="footer">
          <CButton
            @click="showLargeImageModal = false"
            color="secondary"
            class="mr-3"
          >
            Close
          </CButton>
        </template>
      </CModal>
    </div>
  </template>
  
  <script>
  import * as data from "../../model/product"
  import { uploadImage } from "@/utils/fileUpload"
  import FileSaver from "file-saver"
  import firebase from "firebase/compat/app"
  import "firebase/auth"
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  
  export default {
    components: {
      ckeditor: CKEditor.component,
    },
    data() {
      return {
        showLargeImageModal: false,
        editor: ClassicEditor,
        largeImageSrc: '', 
        file: null,
        createModal: false,
        createModalImport: false,
        fields: [],
        editorConfig: {
          toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
        },
        isUpdate: false,
        items: [],
        users: [],
        news: JSON.parse(localStorage.getItem("news")),
        menus: [],
        selectedItems: [],
        isSelectedAll: false,
        selectedAction: 0,
        page: 1,
        total: 0,
        to: 0,
        form: {},
        params: {
          sorttype: "asc",
          sortby: "id",
          row: 50,
          page: 1,
          keyword: "",
        },
        isSearching: false,
        searchOn: "",
        modalPassword: false,
        isPasswordValid: null, //null/boolean
        invalidPassword: "",
        verificators: [{ value: 0, label: "-" }],
        typeOptions: ['Program Kerja', 'Link Terkait'],
      }
    },
    methods: {
      selectImage(event) {
        console.log(event);
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.form.image = resp;  
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      search() {
        if (this.params.keyword != "") {
          this.isSearching = true
          this.getData()
          this.searchOn = this.params.keyword
        } else {
          this.$toast.error("Inputan tidak boleh kosong !!")
        }
      },
  
      searchOff() {
        this.isSearching = false
        this.params.keyword = ""
        this.getData()
      },
      submit() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("product/addProducts", this.form)
          .then(() => {
            this.$toast.success("Berhasil menambahkan Produk");
            loading.hide();
            this.createModal = false;
            this.form = {};
            this.getData();
          })
          .catch((e) => {
            console.error(e);
            this.$toast.error(e);
            loading.hide();
          });
      },
  
      edit(item) {
        this.form = item
        this.isUpdate = true
        this.createModal = true
      },
      update() {
        var loading = this.$loading.show()
        this.$store
          .dispatch("product/updateProducts", { id: this.form.id, data: this.form })
          .then(() => {
            this.$toast.success("Berhasil merubah data Produk")
            loading.hide()
            this.createModal = false
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            loading.hide()
          })
      },
      hapus(item) {
        if (confirm("Data akan dihapus !!")) {
          this.$store
            .dispatch("product/deleteProducts", item.id)
            .then(() => {
              this.$toast.success("Berhasil menghapus data Produk")
              this.form = {}
              this.getData()
            })
            .catch((e) => {
              this.$toast.error(e)
              loading.hide()
            })
        }
      },
      getData() {
        var loading = this.$loading.show()
        this.$store
          .dispatch("product/getProducts", this.params)
          .then((resp) => {
            this.items = resp.data.data
            this.total = resp.data.total
            this.to = resp.data.to
  
            // khusus untuk checkbox
            this.selectedItems = []
            this.items.forEach((element) => {
              if (this.isSelectedAll) {
                element.select = true
                this.selectedItems.push(element.id)
              } else {
                element.select = false
              }
            })
            loading.hide()
          })
          .catch((e) => {
            loading.hide()
          })
      },
      addProducts() {
        this.isUpdate = false
        this.form = {}
        this.createModal = true
      },
      pagination(page) {
        this.page = page
        this.params.page = page
        this.getData()
      },
      cancel() {
        this.form = {}
        this.createModal = false
      },
    },
    computed: {
      computedItems() {
        return this.items.map((item, index) => {
          return {
            index: this.to - this.items.length + index + 1 + ".",
            ...item,
            type : item.type != null ? item.type : "-",
            created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
            updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
          }
        })
      },
    },
    mounted() {
      this.getData()
    },
    created() {
      this.fields = data.fields
    },
  }
  </script>
  